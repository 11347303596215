// extracted by mini-css-extract-plugin
export var multiVendorBenefitsSection = "F_fL";
export var multiVendorBusinessesSection = "F_fD";
export var multiVendorClinetsSection = "F_fQ";
export var multiVendorDevExpertiseSection = "F_fM";
export var multiVendorDiscoverWorksSection = "F_fK";
export var multiVendorFeaturesSection = "F_fC";
export var multiVendorIdeaToLifeSection = "F_fJ";
export var multiVendorIndustriesSection = "F_fF";
export var multiVendorOurAchievementsSection = "F_fP";
export var multiVendorProcessSection = "F_fG";
export var multiVendorServicesSection = "F_fB";
export var multiVendorSuccessStoriesSection = "F_fN";
export var multiVendorTechStackSection = "F_fH";